import React from "react"
import Slider from "react-slick"
import PropTypes from "prop-types"

import ReactPlayer from "react-player"
import Image from "../Image"

import "../../styles/vendor/_slick-defaults.scss"
import styles from "./carousel.module.scss"
import Img from "gatsby-image"

const Carousel = ({ media }) => {
  if (!media) {
    media = []
  }

  const validMedia = media.filter(
    ({ image, video, slide_size }) => (image && image.localFile) || video
  )

  if (!validMedia || validMedia.length === 0) {
    return null
  }

  let slider = null

  const settings = {
    // slidesToShow: 2,
    // variableWidth: true,
    // adaptiveHeight: false,
    arrows: false,
    swipeToSlide: true,
    easing: "easeExpOut",
    fade: false,
    speed: 500,
    // lazyLoad: false,
    className: `${styles.imageSlider}`,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.container}>
      <Slider ref={s => (slider = s)} {...settings}>
        {validMedia.map(({ image, video, slide_size }, index) => (
          <div
            className={styles.slide}
            key={index}
            onClick={() => slider.slickNext()}
          >
            {video ? (
              <ReactPlayer
                url={video.localFile.url}
                playing
                muted
                loop
                volume={0}
                width={null}
                height={null}
                config={
                  image
                    ? {
                        file: {
                          attributes: {
                            poster: image.localFile.childImageSharp.fluid.src,
                          },
                        },
                      }
                    : {}
                }
              />
            ) : (
              // <Image image={image.localFile.childImageSharp.fixed.src} />
              <Img
                className={styles.fullHeightWrapper}
                fluid={image.localFile.childImageSharp.fluid}
              />
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

Carousel.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fixed: PropTypes.shape({
              base64: PropTypes.string,
              width: PropTypes.number,
              height: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
            }),
          }),
        }),
      }),
      video: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    })
  ),
}

Carousel.defaultProps = {
  media: [],
}

export default Carousel
