import React from "react"
import { graphql } from "gatsby"

import ImmersionLayout from "../layouts/immersionLayout"
import SEO from "../components/seo"
import VideoPlayer from "../components/VideoPlayer"
import Carousel from "../components/Carousel"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.wordpressPost
    const postTitle = post.title
    const siteTitle = this.props.data.site.siteMetadata.title
    const fullPage = post.acf.full_page_post
    // const pageGallery = post.acf.full_page_gallery
    const pageGallery = post.acf.page_gallery
    const backgroundColor = post.acf.background
    const backgroundVideo =
      post.acf.background_video != null
        ? post.acf.background_video.source_url
        : ""

    const pageVideo = post.acf.page_video
    return (
      <ImmersionLayout
        location={this.props.location}
        title={siteTitle}
        subtitle={postTitle}
        hideLogo={!!pageVideo}
        backgroundColor={backgroundColor}
        backgroundVideo={backgroundVideo}
      >
        <SEO title={post.title} description={post.excerpt} />

        {fullPage && pageGallery.media_gallery && (
          <Carousel media={pageGallery.media_gallery} />
        )}

        {pageVideo && (
          <div className={``}>
            {/*<ReactPlayer*/}
            {/*  playing={true}*/}
            {/*  url={pageVideo}*/}
            {/*  loop={false}*/}
            {/*  controls={true}*/}
            {/*  playsinline={true}*/}
            {/*  config={{*/}
            {/*    youtube: {*/}
            {/*      playerVars: {*/}
            {/*        showinfo: 0,*/}
            {/*        modestbranding: 1,*/}
            {/*      }*/}
            {/*    },*/}
            {/*  }*/}
            {/*  }*/}
            {/*/>*/}
            <VideoPlayer
              url={pageVideo}
              autoplay={true}
              fullHeight={true}
            ></VideoPlayer>
          </div>
        )}
        <article
          className={`post-content page-template size-immersion no-image`}
        >
          {/*<header className="post-content-header">*/}
          {/*  <h1 className="post-content-title">{post.title}</h1> */}
          {/*</header>*/}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.content }}
          ></div>

          <footer className="post-content-footer"></footer>
        </article>
      </ImmersionLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    wordpressPost(slug: { eq: $slug }, status: { ne: "gatsby_placeholder" }) {
      title
      excerpt
      content
      date(formatString: "MM.DD.YYYY")
      categories {
        name
        slug
      }
      acf {
        background
        page_video
        full_page_post
        page_gallery {
          media_gallery {
            image {
              localFile {
                childImageSharp {
                  #                  fixed(width: 1000, quality: 70) {
                  #                    ...GatsbyImageSharpFixed
                  #                  }
                  fluid(maxWidth: 1500, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            video {
              localFile {
                url
              }
            }
            background_color
            slide_size
            slide_text
          }
        }
        background_video {
          source_url
        }
      }
    }
  }
`
