import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import PassiveMedia from "../PassiveMedia"

import styles from "./video.module.scss"
import Play from "../../svgs/play.svg"

const VideoPlayer = ({
  url,
  video,
  image,
  overline,
  title,
  useButton,
  useControls,
  autoplay,
  fullHeight,
}) => {
  const [isPlaying, setPlayingStatus] = useState(false)
  // setPlayingStatus(autoplay);
  const handleOnEnded = () => {
    setPlayingStatus(false)
  }
  const handleOnUnstarted = () => {
    console.log("unstarted")
    setPlayingStatus(true)
  }

  function hasClicked() {
    const newPlayingStatus = !isPlaying
    setPlayingStatus(newPlayingStatus)
  }

  return (
    <div
      id="video-mod"
      className={`${styles.videoPlayer} ${isPlaying ? styles.showVideo : ""} ${
        fullHeight ? styles.fullHeight : ""
      }`}
    >
      {/*{!isPlaying && (*/}
      {/*  <div className={styles.videoText}>*/}
      {/*    <div className={styles.overline}>{overline}</div>*/}
      {/*    <h3 className={styles.videoTitle}>{title}</h3>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{url ? (*/}
      {/*  <button*/}
      {/*    id="video-trigger"*/}
      {/*    onClick={hasClicked}*/}
      {/*    className={styles.videoTrigger}*/}
      {/*  >*/}
      {/*    <PassiveMedia video={video} image={image} />*/}
      {/*    {useButton && (*/}
      {/*      <span className={styles.play}>*/}
      {/*        <Play />*/}
      {/*      </span>*/}
      {/*    )}*/}
      {/*  </button>*/}
      {/*) : (*/}
      {/*  <PassiveMedia video={video} image={image} />*/}
      {/*)}*/}

      {url && (
        <ReactPlayer
          playing={autoplay}
          url={url + "&vq=hd1080"}
          loop={false}
          controls={true}
          playsinline={true}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: {
                autoplay: 1,
                showinfo: 0,
                enablejsapi: 1,
                modestbranding: 1,
                playsinline: 0,
                listType: "",
                rel: 0,
              },
              onUnstarted: handleOnUnstarted,
            },
          }}
          onEnded={handleOnEnded}
          // controls={useControls}
        />
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  video: PropTypes.object,
  image: PropTypes.object,
  overline: PropTypes.string,
  title: PropTypes.string,
  useButton: PropTypes.bool,
  useControls: PropTypes.bool,
}

export default VideoPlayer
